import { pathify } from '@aex/ngx-toolbox';
import { HttpHeaders } from '@angular/common/http';
import {
	assetsServerUrl,
	billingServiceUrl,
	paymentServiceServer,
	portalServerUrl,
	purchaseServiceServer,
	radiusServerUrl,
	searchUrl,
	serverUrl,
	solarUrl,
	speedTestServerUrl,
} from '../lib/utils';
import { ConfigurationMappingType } from '@app/root-services/types';
export class AuthApi {

	public static operatorHeader(operator: string): HttpHeaders { return new HttpHeaders({ 'X-OPERATOR-ID': operator }); }

	public static get auth(): string { return serverUrl('core', 'auth'); }

}

export class AreaApi {

	public static area = (areaId: string) => serverUrl('areas', areaId);

	public static areaCount = (areaId: string) => serverUrl('areas', areaId, 'total-unit-count');

	public static get areas(): string { return serverUrl('areas'); }

	public static get areasSummary(): string { return serverUrl('areas', 'civil-statuses'); }

	public static areaTasks = (areaTaskId: string) => serverUrl('area-tasks', areaTaskId);

	public static get areaContacts(): string { return serverUrl('area-contacts'); }

	public static areaContact = (areaId: string) => serverUrl('area-contacts', areaId);

	public static areaContactsByArea = (areaId: string) => serverUrl('area-contacts', 'by-area', areaId);

	public static areaType = (areaTypeId: string) => serverUrl('area-types', areaTypeId);

	public static get areaTypes(): string { return serverUrl('area-types'); }

	public static get areaTypeTasks(): string { return serverUrl('area-type-tasks'); }

	public static areaTypeTask = (taskId: number) => serverUrl('area-type-tasks', taskId);

	public static get areaTypeTransitions(): string { return serverUrl('area-type-transitions'); }

	public static areaTypeTransition = (taskId: string) => serverUrl('area-type-transitions', taskId);

	public static areaStatus = (areaStatus: string) => serverUrl('area-statuses', areaStatus);

	public static get areaStatuses(): string { return serverUrl('area-statuses'); }

	public static areaCivilStatus = (areaId: string) => serverUrl('areas', areaId, 'civil_status');

	public static areaTypeGate = (areaTypeGateId: string) => serverUrl('area-type-gates', areaTypeGateId);

	public static get areaTypeGates(): string { return serverUrl('area-type-gates'); }

	public static areaGatesStatus = (areaId: string) => serverUrl('areas', areaId, 'gate-status');

	public static areaHistory = (areaId: string) => serverUrl('areas', areaId, 'history');

	public static city = () =>  serverUrl('cities');

	public static areaParents = (areaId: string) => serverUrl('areas', areaId, 'parents');

	public static get cities(): string { return serverUrl('cities'); }

	public static get provinces(): string { return serverUrl('provinces'); }

	public static get areaCivilStatusSummaryTypes(): string { return serverUrl('areas', 'civil-status-summary-types'); }

	public static get areaCivilStatusSummaryGates(): string { return serverUrl('areas', 'civil-status-summary-gates'); }
}

export class TypeApi {
	public static get types(): string { return serverUrl('types'); }

	public static typeStatuses = (typeId: any) => serverUrl('types', 'statuses', typeId);
}

export class TicketApi {
	public static get tickets(): string { return serverUrl('area-tickets', 'full'); }

	public static get ticketPriorities(): string { return serverUrl('area-ticket-priorities'); }

	public static ticket(ticketId: string): string { return serverUrl('area-tickets', ticketId); }

	public static ticketHistory(ticketId: string): string { return serverUrl('area-tickets', ticketId + '/history'); }

	public static ticketHistoryPost = (area_ticket_history_id: string) => serverUrl('area-ticket-history', area_ticket_history_id);
}

export class CustomerApi {
	public static currentCustomer(token: string): string { return serverUrl('customers', 'by_token', token); }

	public static customer(customerId: string): string { return serverUrl('customers', customerId); }

	public static get customers(): string { return serverUrl('customers'); }

	public static customersByProvider = (providerId: any) => serverUrl('customers', 'providers', providerId);

	public static get idTypes(): string { return serverUrl('identification-types'); }

	public static get roles(): string { return serverUrl('portal-roles'); }
}

export class ProviderApi {
	public static get installers(): string { return serverUrl('installers'); }

	public static providers = (providerId: any) => serverUrl('providers', providerId);

	public static get fnoProviders(): string { return serverUrl('providers?count=all'); }
}

export class SpeedApi {
	public static upload(): string { return speedTestServerUrl('upload'); }
	public static download(): string { return speedTestServerUrl('download'); }
}

export class FilesApi {
	public static files(): string { return serverUrl('files'); }
	public static download = (docId: number) => serverUrl('files', docId, 'download');
}

export class WorkOrderApi {
	public static get workOrders(): string { return serverUrl('work-orders?count=200'); }

	public static proxyWorkOrdersByStatus = (id: any, status: string = '', company: string = '') =>
		serverUrl('proxy', 'work-orders', id, status, company)

	public static fnoWorkOrder = (id: string) => serverUrl('work-orders/linked-status', id);

	public static workOrderHistory = (workOrderId: string) => serverUrl('work-orders', workOrderId, 'history');

	public static workOrder = (workOrderId?: string) => serverUrl('work-orders', workOrderId);

	public static syncWorkOrder = (workOrderId?: string) => serverUrl('work-orders/promote', workOrderId);
}

export class AccountApi {
	public static account = (accountId: any) => serverUrl('accounts', accountId, 'full');
}

export class OperatorApi {
	public static operator(): string { return serverUrl('fnos'); }
}

export class Assets {

	public static assets = (name: string) => serverUrl('assets', name);

	public static get stock(): string { return serverUrl('assets', 'stock'); }

	public static get serviceAssetTypes(): string { return serverUrl('assets', 'service'); }

	public static get darkFiberTypes(): string { return serverUrl('assets', 'dark-fiber'); }

	public static get waveLengthTypes(): string { return serverUrl('assets', 'wave'); }

	public static objectTypeAssets(objectType: string, objectId: string, assetType: string, useAssetIntegrationService: boolean= false): string	{
		if (useAssetIntegrationService)
			return assetsServerUrl('object-types', objectType, 'objects', objectId, 'asset-types', assetType, 'assets');
		else
			return serverUrl('assets', assetType , objectType, objectId);
	}

	public static workOrderAssets(workOrderId: string, useAssetIntegrationService: boolean= false): string {
		return Assets.objectTypeAssets('work-order', workOrderId, 'stock', useAssetIntegrationService);
	}

	public static getWorkOrderAssets(workOrderId: string, useAssetIntegrationService: boolean= false): string {
		return Assets.objectTypeAssets('work-order', workOrderId, 'stock', useAssetIntegrationService);
	}

	public static darkFiberAssets(serviceId: string, useAssetIntegrationService: boolean= false): string {
		return Assets.objectTypeAssets('service', serviceId, 'dark-fiber', useAssetIntegrationService);
	}

	public static waveLengthAssets(serviceId: string, useAssetIntegrationService: boolean= false): string {
		return Assets.objectTypeAssets('service', serviceId, 'wave', useAssetIntegrationService);
	}

	public static serviceAssets(serviceId: string, useAssetIntegrationService: boolean= false): string {
		return Assets.objectTypeAssets('service', serviceId, 'service', useAssetIntegrationService);
	}

	public static getServiceAssets(serviceId: string, useAssetIntegrationService: boolean= false): string {
		return Assets.objectTypeAssets('service', serviceId, 'service', useAssetIntegrationService);
	}

	public static getDarkFiberAssets(serviceId: string, useAssetIntegrationService: boolean= false): string {
		return Assets.objectTypeAssets('service', serviceId, 'dark-fiber', useAssetIntegrationService);
	}
}

export class SettingsApi {
	public static settings(): string { return serverUrl('settings'); }
}

export class NetworkTypesApi {
	public static networkTypes(): string { return serverUrl('network-types'); }

	public static networkType = (networkTypeId: number) => serverUrl('network-types', networkTypeId);
}

export class TechnologyTypesApi {
	public static technologyTypes(): string { return serverUrl('technology-types'); }

	public static technologyType = (technologyTypeId: number) => serverUrl('technology-types', technologyTypeId);
}

export class PredefinedPremiseAPI {
	public static get predefinedPremises(): string { return serverUrl('predefined-premises'); }
}

export class PremiseAPI {
	public static premise(premiseId: string): string { return serverUrl('premises', premiseId); }
}

export class ConfigApi {
	public static get dynamicEnvironment(): string { return pathify('assets', 'environment', 'app-config.json'); }

	public static configSettings = (fnoName: string) => pathify('assets', 'config', `config.${ fnoName }.json`);
}

export class InterconnectsApi {
	public static get coverageRegionsFull(): string { return serverUrl('coverage-regions', 'full'); }

	public static get coverageRegions(): string { return serverUrl('coverage-regions'); }

	public static coverageRegion = (id: number) => serverUrl('coverage-regions', id);

	public static get aexRegions(): string { return serverUrl('aex-regions'); }

	public static get coverageInterconnectsFull(): string { return serverUrl('coverage-interconnects', 'full'); }

	public static get coverageInterconnects(): string { return serverUrl('coverage-interconnects'); }

	public static coverageInterconnect = (id: string) => serverUrl('coverage-interconnects', id);

	public static get coverageProviders(): string { return serverUrl('coverage-providers'); }

	public static coverageProvider = (id: number) => serverUrl('coverage-providers', id);

	public static get coverageProvidersFull(): string { return serverUrl('coverage-providers', 'full'); }

	public static get aexProviders(): string { return serverUrl('aex-providers'); }

	public static get oltRegions(): string { return serverUrl('olt-regions'); }

	public static get oltRegionsFull(): string { return serverUrl('olt-regions', 'full'); }

	public static oltRegion = (id: number) => serverUrl('olt-regions', id);

	public static get olts(): string { return serverUrl('noc/olts'); }

	public static get devices(): string { return serverUrl('noc/devices'); }
}

export class ServiceApi {
	public static service = (serviceId: string) => serverUrl('services', serviceId);

	public static fullService = (serviceId: string) => serverUrl('services', serviceId, 'full');

	public static createService = () => serverUrl('services');

	public static bulkActionService = (action: string) => serverUrl('services', 'bulk-action', action);
}

export class VlansApi {

	public static serviceNodes = (assetReference: string) => serverUrl('noc', 'devices', assetReference);

	public static serviceVlans = (providerId: string, oltId: string) => serverUrl(`vlans?device_id=${oltId}&provider_id=${providerId}`);

	public static serviceISPVlanRanges = (providerId: string) => serverUrl('vlans', providerId, 'vlan_ranges');

}

export class ProductApi {

	public static product = (productId: string) => serverUrl('products', productId);

	public static get products(): string { return serverUrl('products'); }

	public static productType = (id: string) => serverUrl('product-types', id);

}

export class SpeedTestApi {

	public static createSpeedTest = (serial: string, port: number) => serverUrl('devices', serial, 'speed-tests', port);

	public static speedTest = (speedTestId: string) => serverUrl('speed-tests', speedTestId);

	public static get speedTests(): string { return serverUrl('speed-tests'); }

}

export class RadiusApi {

	public static macAddress = (ipAddress: string) => radiusServerUrl(ipAddress, 'mac');

}

export class DevicesApi {

	public static services = (macAddress: string) => serverUrl('devices', macAddress, 'services');

}

export class PurchaseOrderApi {

	public static get paymentHistory(): string { return purchaseServiceServer('payment-history'); }

	public static get activePaymentMedium(): string { return purchaseServiceServer('active-payment-medium-for-service'); }

	public static downloadInvoice = (operatorId: string, serviceId: string, id: string) => purchaseServiceServer('invoice', operatorId, serviceId, id);

	public static get getProductConfig(): string { return purchaseServiceServer('config'); }

	public static setDebitDay = () => purchaseServiceServer('set-debit-day');

	public static getPlanByService = (serviceId: string): string => purchaseServiceServer('purchase-plan', 'service', serviceId);

	public static get getPricingInfo(): string { return purchaseServiceServer('pricing-info'); }

	public static createPaymentLinkPurchase = (serviceId: string): string => purchaseServiceServer('payment-link-purchase',serviceId);

	public static createPaymentLinkPayment = (purchaseId : string): string => purchaseServiceServer('payment-link-payment',purchaseId);

	public static createOffSystemPayment = (purchaseId: string): string => purchaseServiceServer('purchases', purchaseId, 'payments');

	public static createOffSystemPaymentForUnsuspension = (serviceId: string): string => purchaseServiceServer('services', serviceId, 'purchases', 'from-payment');

	public static get purchaseSource(): string { return purchaseServiceServer('purchase-source'); }

	public static getBillingSchemeByType = (configurationMappingType: ConfigurationMappingType, value: string): string =>
    purchaseServiceServer('configurations', 'billingScheme', configurationMappingType, value);
}

export class PaymentApi {

	public static paymentAdjustments = (serviceId: string) => paymentServiceServer('payments', 'adjustment', serviceId);

}

export class DeviceManagementApi {

	public static updateAccount = (accountId: string) => serverUrl('accounts', accountId, 'work_orders');

	public static updateSerial = (premiseId: string) => serverUrl('premises', premiseId, 'serial-number');

	public static reconfigureSerial = (premiseId: string) => serverUrl('premises', 'devices', premiseId);

	public static l3ToL2 = (accountId: string) => serverUrl('accounts', accountId, 'l3_l2_reconfiguration');

	public static reallocateIp = (serviceId: string) => serverUrl('services', serviceId, 're-allocate-ip');

}

export class SearchServiceApi {

	public static get workOrdersSearch(): string { return searchUrl('work-orders', 'search'); }

	public static get workOrdersSearchCsv(): string { return searchUrl('work-orders', 'csv'); }

	public static get workOrderColumns(): string { return searchUrl('work-orders', 'columns'); }

	public static get statuses(): string { return serverUrl('statuses'); }

	public static get types(): string { return serverUrl('types'); }

	public static get regions(): string { return serverUrl('regions'); }

	public static get areas(): string { return serverUrl('areas'); }

	public static get serviceSearch(): string { return searchUrl('services', 'search'); }

	public static get serviceSearchCsv(): string { return searchUrl('services', 'csv'); }

	public static get serviceStatuses(): string { return serverUrl('service-statuses'); }

	public static get serviceColumns(): string { return searchUrl('services', 'columns'); }

}

export class IspAreaManagmentApi {

	public static deleteIspArea = (areaId: string) => serverUrl('areas', 'deletearea', areaId);

	public static addIspArea = () => serverUrl('areas', 'addArea');

}

export class FusionSolarApi {

	public static discoverAssigned = (plantId: string) => solarUrl('network', 'discover-assigned', plantId);

	public static monitor = (plantId: string) => solarUrl('network', 'monitor', plantId);

	public static networkAlarms = (plantId: string) => solarUrl('network', 'monitor-alarms', plantId);

	public static current = (plantId: string) => solarUrl('search', 'plants-current', plantId);

	public static daily = (plantId: string) => solarUrl('search', 'plants-daily', plantId);

	public static plantAlarms = (plantId: string) => solarUrl('search', 'plants-alarm', plantId);

}

export class BillingApi {

	public static get invoiceLineItems(): string { return serverUrl('invoice-line-items'); }

	public static invoiceLineItem = (id: string) => serverUrl('invoice-line-items', id);

	public static get invoiceLineItemTypes(): string { return serverUrl('invoice-line-item-types'); }

	public static get servicePriceOverrides(): string { return serverUrl('service-price-overrides'); }

	public static servicePriceOverride = (id: string, user: string) => serverUrl(`service-price-overrides/${id}?modified_by=${user}`);

	public static get priceTypes(): string { return serverUrl('price-types'); }

	public static get productPrices(): string { return serverUrl('product-prices'); }

	public static get products(): string { return serverUrl('products'); }

	public static product = (id: string) => serverUrl('products', id);

	public static get providers(): string { return serverUrl('providers'); }

}

export class BoltOnServicesApi {

	public static boltOnServices(): string { return serverUrl('bolt-on-services'); }

	public static get boltOnProducts(): string { return serverUrl('bolt-on-products'); }

	public static boltOnService(id?: string): string { return serverUrl('bolt-on-services', id); }

	public static cancelBoltOnService(id?: string): string { return serverUrl('bolt-on-services', 'cancel', id); }
}

export class StatusApi {

	public static operationalStatus = (serialNumber: string) => serverUrl('network', 'status', serialNumber, 'status');

	public static operationalStatusHistory = (serialNumber: string) => serverUrl('network', 'status', serialNumber, 'history');

	public static serviceStatus = (serialNumber: string, portNumber: number) => serverUrl('network', 'status', serialNumber, 'service', portNumber);

	public static portStatus = (serialNumber: string, portNumber: number) => serverUrl('network', 'status', serialNumber, 'service', portNumber);

	public static networkPower = (serialNumber: string) => serverUrl('network', 'status', serialNumber, 'power');

	public static deviceConfiguration = (serialNumber: string, portNumber: number) => serverUrl('network', 'status', serialNumber, 'config', portNumber);

	public static portConfiguration = (serialNumber: string, portNumber: number) => serverUrl('network', 'status', serialNumber, 'config', portNumber);

	public static wifiDevices = (serialNumber: string) => serverUrl('network', 'status', serialNumber, 'wifi-devices');

	public static wifiInformation = (serialNumber: string) => serverUrl('network', 'status', serialNumber, 'wifi-information');

	public static fullServicePortConfiguration = (serialNumber: string, portNumber: number) => serverUrl('network', 'status', serialNumber, 'full-service', portNumber);

	public static fullServiceStatus = (serialNumber: string) => serverUrl('network', 'status', serialNumber, 'full-status');

	public static radiusStatus = (serialNumber: string, portNumber: number) => serverUrl('network', 'status', serialNumber, 'radius-status', portNumber);
}

export class PortalApi {

	public static get sendPMONotification(): string { return portalServerUrl('account', 'SendNotification'); }

}
export class BillingReportApi {
	public static billingReportLineItems(): string { return billingServiceUrl('billing-report', 'report-line-items'); }

	public static get exportBillingReportLineItems(): string { return billingServiceUrl('billing-report', 'report-csv'); }
}
